<template>
  <Header />
  <HomePage />
  <Footer />
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import Header from "./components/Header.vue";
import Footer from "./components/Footer.vue";
import HomePage from "./components/HomePage.vue";

@Options({
  components: {
    Header,
    HomePage,
    Footer,
  },
})
export default class App extends Vue {}
</script>

<style></style>
