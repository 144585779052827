<template>
  <section class="hero is-link is-medium">
    <p class="title m-4 p-4">Ares Silver</p>
    <div class="hero-body"></div>
  </section>
</template>

<script lang="ts">
import { Vue } from "vue-class-component";

export default class Header extends Vue {}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.hero {
  background-image: url("../assets/img/ares-silver-banner.jpg") !important;
  background-size: cover;
  background-position: center top;
}

h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
