<template>
  <footer class="footer">
    <div class="container content has-text-centered is-vcentered">
      <p>
        Made by Jenna <a href="https://github.com/jennaseto/ares-silver">🦄</a>
      </p>
    </div>
  </footer>
</template>

<script lang="ts">
import { Vue } from "vue-class-component";

export default class Footer extends Vue {}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.hero {
  background-image: url("../assets/img/ares-silver-banner.jpg") !important;
  background-size: cover;
  background-position: center top;
}

h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
