<template>
  <div class="media-list">
    <div v-if="isAlbum">
      <div v-for="album in albums" :key="album.title">
        <div class="my-4">
          <article class="media">
            <div class="media-left">
              <figure class="image is-128x128">
                <img
                  :src="
                    require(`../assets/img/album-covers/${album.albumCover}`)
                  "
                />
              </figure>
            </div>

            <div class="media-content">
              <div class="content">
                <p class="subtitle mb-1">
                  <strong>{{ album.title }}</strong>
                </p>

                <p class="mt-1">{{ album.releaseDate }}</p>

                <div class="level is-mobile">
                  <div class="level-left is-vcentered">
                    <div
                      class="level-item"
                      v-for="link in album.links"
                      :key="link.label"
                    >
                      <div class="mx-1">
                        <a class="icon" :href="link.url">
                          <img
                            :src="require(`../assets/img/icons/${link.icon}`)"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </article>
        </div>
      </div>
    </div>
    <div v-else>
      <div v-for="track in tracks" :key="track.title">
        <div class="my-4">
          <article class="media">
            <div class="media-left">
              <figure class="image is-128x128">
                <img
                  :src="
                    require(`../assets/img/album-covers/${track.albumCover}`)
                  "
                />
              </figure>
            </div>
            <div class="media-content">
              <div class="content">
                <p class="subtitle mb-1">
                  <strong>{{ track.title }}</strong>
                </p>

                <p class="mt-1">{{ track.releaseDate }}</p>

                <div class="level is-mobile">
                  <div class="level-left is-vcentered">
                    <div
                      class="level-item"
                      v-for="link in track.links"
                      :key="link.label"
                    >
                      <div class="mx-1">
                        <a class="icon" :href="link.url">
                          <img
                            :src="require(`../assets/img/icons/${link.icon}`)"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </article>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Collection } from "../types/Collection";
import mediaList from "../assets/links/media-links.json";
import { defineProps, ref } from "vue";

const props = defineProps<{ isAlbum: boolean }>();
const isAlbum = ref<boolean>(props.isAlbum);

const albums: Collection[] = mediaList.albums;
const tracks: Collection[] = mediaList.tracks;
</script>
