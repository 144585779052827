<template>
  <section class="section home-page-section">
    <div class="container">
      <h1 class="title">Projects</h1>
      <!-- <p class="subtitle">Catelog of all music!</p> -->

      <!-- <ProjectView /> -->
      <div class="columns">
        <div class="column is-two-thirds">
          <div class="columns">
            <div class="column is-half my-4">
              <p class="subtitle">EPs & Albums</p>
              <MediaList v-bind:isAlbum="true" />
            </div>

            <div class="column is-half my-4">
              <p class="subtitle">Singles</p>
              <MediaList v-bind:isAlbum="false" />
            </div>
          </div>
        </div>

        <div class="column"></div>

        <div class="column">
          <p class="subtitle">Links</p>
          <hr class="navbar-divider" />
          <div class="columns mb-2">
            <div class="column is-11 is-vcentered">
              <p class="mb-4"><strong>Media</strong></p>
              <div
                class="mb-4"
                v-for="mediaLink in mediaLinks"
                :key="mediaLink.icon"
              >
                <div class="level is-mobile">
                  <div class="level-left">
                    <a :href="mediaLink.url">
                      <div class="level-item">
                        <div class="mx-2">
                          <div class="image is-16x16">
                            <img
                              :src="
                                require(`../assets/img/icons/${mediaLink.icon}`)
                              "
                            />
                          </div>
                        </div>
                        <p class="subtitle is-size-6">{{ mediaLink.label }}</p>
                      </div>
                    </a>
                  </div>
                </div>
              </div>

              <hr class="navbar-divider" />

              <p class="mb-4"><strong>Social</strong></p>
              <div
                class="mb-4"
                v-for="socialLink in socialMediaLinks"
                :key="socialLink.icon"
              >
                <div class="level is-mobile">
                  <div class="level-left">
                    <a :href="socialLink.url">
                      <div class="level-item">
                        <div class="mx-2">
                          <div class="image is-16x16">
                            <img
                              :src="
                                require(`../assets/img/icons/${socialLink.icon}`)
                              "
                            />
                          </div>
                        </div>
                        <p class="subtitle is-size-6">{{ socialLink.label }}</p>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { Collection } from "../types/Collection";
import mediaList from "@/assets/links/media-links.json";
import socialList from "@/assets/links/social-links.json";
import MediaList from "./MediaList.vue";
import { Link } from "@/types/Link";

@Options({
  components: {
    MediaList,
  },
})
export default class HomePage extends Vue {
  albums: Collection[] = mediaList.albums;
  tracks: Collection[] = mediaList.tracks;
  mediaLinks: Link[] = socialList.media;
  socialMediaLinks: Link[] = socialList.social_media;
}
</script>

<style scope>
.home-page-section {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  width: 100%;
}

.card {
  display: flex;
  justify-content: center;
  align-content: center;
}

.media-content {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: baseline;
}
</style>
